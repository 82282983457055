import logo from './logo.svg';
import { Route, Routes, Navigate , useLocation} from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar';
import Home from './Views/Home';
import Footer from './Components/Footer';
import Services from "./Views/Services";
import Contact from './Views/Contact';
import { Fade } from 'react-awesome-reveal';
import SocialTree from './Views/Reviews';

function App() {

	const location = useLocation();

	return (
		<main>
			{location.pathname !== '/Reviews'? (
        		<Navbar />
     	 	):null}
			<Fade delay={100}>
				<Routes>
					<Route path='/' element = {<Home />} />
					<Route path='/Services' element = {<Services />} />
					<Route path='/Contact' element = {<Contact />} />
					<Route path='/Reviews' element = {<SocialTree />} />
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</Fade>
			{location.pathname !== '/Reviews'? (
				<Footer />
			):null}
		</main>
  	);
}

export default App;
