import { React } from "react"
import Logo from "../img/Logo.png";
import ServicesData from "./ServicesData";

export default function Footer() {
    return (
        <footer className="bg-[#fcfaf5] cursor-pointer">
            <div className="mx-auto space-y-4 max-w-screen-xl px-6 pb-6 pt-16 lg:px-8 lg:pt-24">
                <div className="grid lg:space-x-12 grid-cols-1 gap-12 lg:grid-cols-3">
                    <div className="grid justify-items-center">
                        <div>
                            <img alt="#Logo" className="md:w-44 md:flex" src={Logo} />
                        </div>
                        <p className="mt-6 max-w-md tracking-wide leading-relaxed text-black sm:max-w-xs text-justify sm:text-left font-serif">
                            Welcome to T&T Beauty Spa, where you will be greeted by our courteous and friendly staff.
                            Our technicians are professionally trained to offer the highest quality experiences utilizing the highest quality products.
                        </p>

                        <ul className="mt-8 flex justify-center gap-6 md:gap-8">
                            <li className="">
                                <a href="https://www.facebook.com/profile.php?id=61550839471126" target="_blank" className="text-teal-700 transition hover:text-teal-700/75">
                                    <img alt="facebook" src="https://img.icons8.com/fluent/30/000000/facebook.png" />
                                </a>
                            </li>
                            <li className="">
                                <a href="https://www.yelp.com/biz/t-and-t-beauty-spa-pleasant-hill-2" target="_blank" className="text-teal-700 transition hover:text-teal-700/75">
                                    <img alt="yelp" src="https://img.icons8.com/fluent/30/000000/yelp.png" />
                                </a>
                            </li>
                            <li>
                                <a href="https://maps.app.goo.gl/NTXgz32JVnPdyiTS7" target="_blank" className="text-teal-700 transition hover:text-teal-700/75">
                                    <img alt="google" src="https://img.icons8.com/fluency/30/google-logo.png" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/ttbeautyspapleasant/" target="_blank" className="text-teal-700 transition hover:text-teal-700/75">
                                    <img alt="instagram" src="https://img.icons8.com/fluency/30/instagram-new--v1.png" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4 font-serif lg:col-span-2">
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color">About Us</p>
                            <ul className="mt-8 space-y-4 text-sm">
                                <li>
                                    <a href="/" className=" transition text-black hover:text-main-color">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a href="/Services" className=" transition text-black hover:text-main-color">
                                        Services
                                    </a>
                                </li>
                                <li>
                                    <a href="/Contact" className=" transition text-black hover:text-main-color">
                                        Contact Us
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color">Services</p>
                            <ul className="mt-8 space-y-4 text-sm">
                                {ServicesData.map((s) => (
                                    <li key={s.id}>
                                        <a target="_blank" href="/Services" className=" transition text-black hover:text-main-color">
                                            {s.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color">Business Hours</p>
                            <ul className="mt-8 space-y-4 text-sm">
                                <li>
                                    <a className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Mon: 9:30 am - 7:30 pm
                                    </a>
                                </li>
                                <li>
                                    <a className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Tues: 9:30 am - 7:30 pm
                                    </a>
                                </li>
                                <li>
                                    <a className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Wed: 9:30 am - 7:30 pm
                                    </a>
                                </li>
                                <li>
                                    <a className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Thu: 9:30 am - 7:30 pm
                                    </a>
                                </li>
                                <li>
                                    <a className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Fri: 9:30 am - 7:30 pm
                                    </a>
                                </li>
                                <li>
                                    <a className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Sat: 9:30 am - 7:30 pm
                                    </a>
                                </li>
                                <li>
                                    <a className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Sun: 10:00 am - 7:00 pm
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color">Contact Us</p>
                            <ul className="mt-8 space-y-5 text-sm">
                                <li>
                                    <a className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end" href="tel:9253499958">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-8 w-8 shrink-0 text-main-color"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                            />
                                        </svg>
                                        <span className="flex-1 text-black">(925) 349-9958</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end" href="mailto:tt_beautyspa@outlook.com">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-8 w-8 shrink-0 text-main-color"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                            />
                                        </svg>
                                        <span className="flex-1 text-gray-700">tt_beautyspa@outlook.com</span>
                                    </a>
                                </li>
                                <li className="flex items-start justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-8 w-8 shrink-0 text-main-color"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                    </svg>
                                    <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/NTXgz32JVnPdyiTS7" className="-mt-0.5 flex-1 not-italic text-black">
                                        1944 Contra Costa Blvd, Pleasant Hill, CA 94523
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                
                <hr className=""></hr>
                <div className="mt-12 border-t border-gray-100 pt-6">
                    <div className="text-center sm:flex sm:justify-between sm:text-left">
                        <p className="text-sm text-black">
                            <span className="block sm:inline">All rights reserved.</span>
                        </p>

                        <p className="mt-4 text-sm text-black sm:order-first sm:mt-0">
                            &copy; 2024 TT Beauty Spa
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};